import React, { useEffect, useLayoutEffect } from "react"
import Layout from "../components/layout"
import sliderImage from "../images/o_nas/01.jpg"
import sliderImageMobile from "../images/m_02.jpg"
import sliderImageSmall from "../images/m_02b.jpg"
import ScrollBlock from "../components/scrollBlock"
import { InView } from "react-intersection-observer"
import LinkBox from "../components/LinkBox"
import Banner from "../components/Banner"
import SubBanner from "../components/career/SubBanner"
import Info from "../components/career/Info"
import Steps from "../components/career/Steps"
import JobOffers from "../components/career/JobOffers"
import { useTranslation } from "react-i18next"

const CareerPage = (props) => {
  const { t, i18n } = useTranslation();
  if (i18n.language !== "pl") i18n.changeLanguage("pl")
  
  return (
    <Layout props={props}>
      <div className="slider relative bg-black">
        <Banner
          sliderImage={sliderImage}
          sliderImageMobile={sliderImageMobile}
          sliderImageSmall={sliderImageSmall}
          title01={t("career.join")}
          title02={t("general.us")}
          subtitle={t("career.title")}
          id="banner"
          link1={t("general.fleetHref")}
          link2={t("general.contactHref")}
        ></Banner>
        <ScrollBlock></ScrollBlock>
      </div>
      <SubBanner />
      <Info />
      <Steps />
      <JobOffers />
      <section>
        <div className="flex flex-wrap">
          <div className="tb:w-1/2 w-full">
            <LinkBox
              link="/o-nas/"
              cssClass="about"
              text={t("general.about")}
            ></LinkBox>
          </div>
          <div className="tb:w-1/2 w-full">
            <LinkBox
              link="/kontakt/"
              cssClass="contact"
              text={t("general.contact")}
            ></LinkBox>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default CareerPage;